import { ref } from 'vue';

const sidebarContent = ref('STYLE_GUIDES');

/**
* * switchSidebarContent() - changes sidebar content (either style guides or filters)
*/
export default () => {
    /**
     * @param {String} contentType
     */
    const switchSidebarContent = (contentType) => {
        sidebarContent.value = contentType;
    };
    const resetSidebarContent = () => {
        switchSidebarContent('STYLE_GUIDES');
    };

    return {
        sidebarContent,
        switchSidebarContent,
        resetSidebarContent
    };
};
