<template>
  <div v-if="pageLoading" class="text-center w-full loading-display">
    <base-svg
      class="h-4 w-4 mr-1 text-primary-red inline-block cursor-pointer"
      src="icons/circleSpinner.svg"
      tag="span"
    />
    Please wait while the system is applying your access rights to retrieve Style Guides. The screen will be loaded with all
    the artwork you have access to when the progress bar disappears.
  </div>
  <div v-else>
    <div class="flex flex-row mb-8">
      <style-guide-side-bar v-if="showSideBar" class="w-1/4 flex-shrink-0" />
      <style-guide-gallery class="flex-grow" />
    </div>
  </div>
  <app-footer />
</template>

<script>
import { defineAsyncComponent, ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import useStyleGuideAssets from '@/hooks/styleGuideAssets.js';
import BaseSvg from '@/components/generic-components/BaseSvg.vue';
import AppFooter from '@/components/AppFooter.vue';
import useBasket from '@/hooks/basket.js';

export default {
    name: 'StyleGuides',

    components: {
        StyleGuideSideBar: defineAsyncComponent(() => import('@/components/style-guide-sidebar/StyleGuideSidebar.vue')),
        StyleGuideGallery: defineAsyncComponent(() => import('@/components/style-guide-gallery/StyleGuideGallery.vue')),
        BaseSvg,
        AppFooter
    },

    setup () {
        const store = useStore();
        const { fetchBasket } = useBasket();
        onMounted(() => {
            fetchBasket();
        });
        // style guide tree for side bar
        const styleGuideDirectory = computed(() => store.getters['styleGuideTree/getStyleGuideDirectory']);
        const pageLoading = ref(true);
        const fetchDirectoryData = async () => {
            try {
                pageLoading.value = true;
                await store.dispatch('styleGuideTree/fetchStyleGuideDirectory', {
                    params: {
                        metadataTemplateName: 'Cover'
                    }
                });
            } catch (err) {
                console.error(err);
            } finally {
                pageLoading.value = false;
            }
        };

        // assets search logic
        const { assetsList } = useStyleGuideAssets();
        const searchInitiated = computed(() => store.getters['assetsSearch/getSearchInitiated']);
        const showSideBar = computed(() => {
            return true
            return searchInitiated.value && assetsList.value && assetsList.value.data ? assetsList.value.data.length > 0 : true;
        });
        onMounted(() => {
            if (styleGuideDirectory.value) {
                pageLoading.value = false;
            } else {
                fetchDirectoryData();
            }
        });

        return {
            pageLoading,
            searchInitiated,
            assetsList,
            showSideBar
        };
    }
};
</script>

<style>
  .loading-display {
    height: 72vh;
    padding-bottom: 20%;
  }
</style>
