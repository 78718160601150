<template>
  <hr class="my-2 mx-6 block border-t border-solid border-custom-gray-2">
  <div class="px-6 mb-8 text-xs text-custom-gray-8 text-justify">
    Marvel must give written approval prior to each use of its property by a licensee. This approval must precede
    the advertising, sale, manufacture, or any promotional use of the property. Approval of licensee's artwork or
    submitted materials does not constitute approval of or permission to produce a product not expressly
    authorized pursuant to licensee's license agreement. Nor shall any approval ever act or be construed as an
    amendment or modification of the license agreement. Please be reminded that no legally binding license
    agreement will exist until a prospective licensee has received from Marvel a fully executed license
    agreement, nor shall an approval constitute an agreement between Marvel and a prospective licensee or be
    deemed legal authorization absent a fully executed license agreement. Expenses incurred by you in
    connection with a proposed licensing relationship including artwork or other development costs, even if
    approved prior to your receipt of a fully executed formal license agreement, are entirely at your own risk.
    This approval does not expand licensor's representations and warranties in the license agreement, nor
    authorize licensee to use copyrights, trademarks, or other materials owned or controlled by third parties, the
    clearance of which is the licensee's sole responsibility. The contents of this website are subject to your
    confidentiality agreement with Marvel. Activity on this website is monitored and tracked. © MARVEL
  </div>
</template>

<script>
export default {
    name: 'AppFooter'
};
</script>
