import { useStore } from 'vuex';
import { ref } from 'vue';

export default () => {
    const store = useStore();
    const basketLoading = ref(false);

    /**
     * @param {Array | Object} basketItems
     */
    const addToBasket = async (basketItems) => {
        try {
            if (basketLoading.value) {
                return;
            }
            basketLoading.value = true;
            const basketItemsList = store.getters['basket/getBasketList'];
            const payLoadId = {
                ids: []
            };
            basketItems.ids.forEach(item => {
                const matchIndex = basketItemsList.findIndex(list => list.originalFileId === item);
                if (matchIndex === -1) {
                    payLoadId.ids.push(item);
                }
            });
            await store.dispatch('basket/addToBasket', payLoadId);
            await fetchBasket();
        } catch (err) {
            console.error(err);
        } finally {
            basketLoading.value = false;
        }
    };

    // fetching basket logic
    const fetchBasket = async () => {
        try {
            basketLoading.value = true;
            await store.dispatch('basket/fetchBasketList');
        } catch (err) {
            console.error(err);
        } finally {
            basketLoading.value = false;
        }
    };

    /**
     * @param {Array | Object} basketItems
     */
    const removeFromBasket = async (id) => {
        try {
            if (basketLoading.value) {
                return;
            }
            basketLoading.value = true;
            await store.dispatch('basket/removeFromBasket2', id);
        } catch (err) {
            console.error(err);
        } finally {
            basketLoading.value = false;
        }
    };

    return {
        basketLoading,
        addToBasket,
        fetchBasket,
        removeFromBasket
    };
};
