import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import useStyleGuideSidebar from '@/components/style-guide-sidebar/styleGuideSidebar.js';

const assetsLoading = ref(false);

export default () => {
    const store = useStore();
    const route = useRoute()


    // fetch assets logic
    const assetsList = computed(() => store.getters['assets/getAssetsList']);
    const fetchAssetsList = async (payload) => {
        try {
            assetsLoading.value = true;

            const tmp = store.getters['assetsSearch/getSearchWithInParams']
            payload.params = { ...payload.params, ...tmp }

            let facetsList = await store.dispatch('assets/fetchAssetsList', payload);
            return facetsList

        } catch (err) {
            console.error(err);
        } finally {
            assetsLoading.value = false;
        }
    };

    // search assets logic
    const { switchSidebarContent } = useStyleGuideSidebar();
    const searchAssets = async (payload) => {
        try {
            if (!payload || !payload.params) payload = { params: {} }

            // await store.dispatch('styleGuideTree/collapseSelectedNodes');
            await store.dispatch('assetsSearch/updateSearchInitiated', { isSearchInitiated: true });
            const params = { ...searchParams.value, ...payload.params }
            let facetsList = await fetchAssetsList({ params })

            if (facetsList && facetsList.data && facetsList.data.length) switchSidebarContent('FILTERS');
        } catch (err) {
            console.error(err);
        }
    };

    // assets search params logic
    const searchParams = computed(() => store.getters['assetsSearch/getSearchParams']);
    const saveSearchParams = async (params, append=false) => {
        try {
            if (append) params = { ...searchParams.value, ...params }
            await store.dispatch('assetsSearch/saveSearchParams', params);
        } catch (err) {
            console.error(err);
        }
    };
    const clearSearchParams = async () => {
        await store.dispatch('assetsSearch/clearSearchParams');
    };

    // asset search filters logic
    const clearAllSearchFilters = async () => {
        await store.dispatch('assetsSearch/clearAllSearchFilters');
    };

    // reset assets, search and directory navigation logic
    const resetAssetsAndSearch = async (newRoute=route) => {
        switch (newRoute.name) {
          case 'Basket':
            break;

          case 'StyleGuide':
            await store.dispatch('assetsSearch/resetAssetsSearch');
            break;

          case 'BrandAssurance':
            await store.dispatch('assetsSearch/setSearchWithInFlag', { searchWithInFlag: false })
            await store.dispatch('assetsSearch/resetAssetsSearch');
            break;
        }

        await store.dispatch('styleGuideTree/resetSelectedNodeStack');
        await store.dispatch('assets/resetAssets');
        switchSidebarContent('STYLE_GUIDES');
    };

    return {
        assetsLoading,
        assetsList,
        fetchAssetsList,
        searchAssets,
        searchParams,
        clearSearchParams,
        saveSearchParams,
        resetAssetsAndSearch,
        clearAllSearchFilters
    };
};
